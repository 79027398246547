import React  from 'react';
import { Link, graphql } from 'gatsby';
import { useFirebase } from 'gatsby-plugin-firebase';

import Layout from '../components/layout'
import Block from '../components/block'

const IndexPage = ({ data }) => { 
  const page = data.strapiPageHome

  const CallToAction = page.CallToAction && (
    <section className="call-to-action primary">
      <div className="content extra column">
        <div className="header space-between">
          <div className="title">
            <h4>{ page.CallToAction.Title }</h4>
            <h3 dangerouslySetInnerHTML={{ __html: page.CallToAction.Tagline }} />
          </div>

          <Link to={ page.CallToAction.ButtonLink } className="button inverse mob-icon">
            <i className="fas fa-chevron-right" />
            <span>{ page.CallToAction.ButtonText }</span>
          </Link>
        </div>
      </div>
    </section>
  )

  return ( 
    <Layout colorTheme="primary" bgColorTheme="white">
      <main id="index">
        <section className="hero white">
          <div className="content extra">
            <div className="tagline column" style={{ maxWidth: 560 }}>
              <h1>We ship software faster <br /> so everyone's happy</h1>
              <p>When you can ship software out in  8 weeks instead of 6 months, your users are happy. And that means your boss is happy. So you're happy. The only people not happy? Your competitors.</p>
              <Link to="/big-idea" className="button">Read our big idea</Link>
            </div>
            <div className="alt">
              <div className="wrapper">
                <div className="image-wrapper breakout">
                  <div className="image breakout" style={{ width: '124%', backgroundImage: ["url(", "../images/home_hero.png", ")"].join("")}}></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="clients bright">
          <div className="content center column">
            <h4>{ page.ClientsHeader }</h4>
            <div className="client-logos">
              { 
                page.Clients.map((client) => (
                  <div className="container" key={ client.id }>
                    <img src={ client.Logo.publicURL } alt={ client.name } />
                  </div>
                ))
              }
            </div>
          </div>
        </section>

        <section className="work bright">
          <div className="content wrap space-evenly">
            <div className="languages column">
              <h3>{ page.TechHeader }</h3>
              <p>{ page.TechBody }</p>
              <div className="dev-icons">
                { 
                  data.allStrapiTechnology.nodes.map((language) => ( <div className="container" key={ language.id } display-name={ language.Name }><i className={ language.Icon } /></div> )) 
                }
              </div>
            </div>
            <div className="services column card-view">
              {
                data.allStrapiService.nodes.map((service) => (
                  <div key={ service.id } className="service row card">
                    <div className="icon">
                      <i className={ service.Icon} />
                    </div>
                    <div className="info column">
                      <h5>{ service.Name }</h5>
                      <p>{ service.Description }</p>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </section>

        <section className="study white">
          <div className="content center">
            <Block modifiers="case-study">
              <div className="header space-between">
                <div className="title">
                  <h5 className="subtext">Ohio State University</h5>
                  <h3>Making it easy to find<br />the correct data</h3>
                </div><Link to="/work/osu" className="button inverse mob-icon"><i className="fas fa-chevron-right" /><span>View Case Study</span></Link>
              </div>
              <img className="screenshot" src="./images/screenshot.jpg" />
            </Block>
          </div>
        </section>

        <section className="reviews white">
          <div className="content column center">
            <h3>{ page.TestimonialsHeader }</h3>
            <div className="grid col-auto">
              {
                data.allStrapiTestimonial.nodes.map((testimonial) => (
                  <div key={ testimonial.id } className="review">
                    <p>"{testimonial.Quote}"</p>
                    <div className="img" style={{ backgroundImage: `url(${testimonial.Photo.publicURL})` }} />
                    <h5 className='cap'>{ testimonial.Name }</h5>
                    <h4>{ testimonial.Company }</h4>
                  </div>
                ))
              }
            </div>
          </div>
        </section>
  
        { CallToAction }
      </main>
    </Layout>
  )
}

export default IndexPage;

export const query = graphql`
  query Home {
    strapiPageHome {
      Clients {
        id
        Name
        Logo {
          publicURL
        }
      }
      CallToAction {
        Title
        Tagline
        ButtonText
        ButtonLink
      }
      ClientsHeader
      TechHeader
      TechBody
      TestimonialsHeader
    }

    allStrapiTechnology {
      nodes {
        id
        Name
        Icon
      }
    }

    allStrapiService {
      nodes {
        id
        Name
        Description
        Icon
      }
    }

    allStrapiTestimonial {
      nodes {
        id
        Name
        Company
        Quote
        Photo {
          publicURL
        }
      }
    }
  }
`
